import { gql } from '@apollo/client'

/*
 * This mutation is responsible for posting the
 * Auth Code (received from the Auth service)
 * to the BSP Processor in order to check its validity
 */

const LOGIN_BY_AUTH_CODE = gql`
  mutation LoginByAuthCode ($accessCode: String!) {
    loginByAuthCode(accessCode: $accessCode) {
      login
      role
    }
  }
`

export default LOGIN_BY_AUTH_CODE
