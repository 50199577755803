import React, { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Navbar from 'react-bootstrap/Navbar'
import Row from 'react-bootstrap/Row'
import Spinner from 'react-bootstrap/Spinner'
import User from '@utils/storage/user'
import ExternalAuthentication from '@utils/external-authentication'
import toast from '@utils/toast'
import LOGIN_BY_AUTH_CODE from '@graphql/mutations/login-by-auth-code'

function ExternalAuthenticationPage() {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [loginByAuthCode, { loading, error }] = useMutation(LOGIN_BY_AUTH_CODE, {
    onCompleted(data) {
      User.set({ login: data.loginByAuthCode.login, role: data.loginByAuthCode.role })
      navigate('/transactions')
      toast('Logged in!')
    },
    onError() {
      toast("Authentication wasn't successful!", 'danger')
    }
  })

  useEffect(() => {
    loginByAuthCode({ variables: { accessCode: searchParams.get('code') } })
  }, [])

  return (
    <>
      <Navbar expand='lg' bg='dark' variant='dark' style={{ height: '58px' }}>
        <Container fluid='xl'>
          <Navbar.Brand>BSP Processor</Navbar.Brand>
        </Container>
      </Navbar>
      <Container fluid='xl' className='py-5'>
        { loading && (
          <div className='d-flex justify-content-center'>
            <Spinner animation='border' role='status' className='my-2' />
          </div>
        )}
        { error && (
          <Card>
            <Card.Body className='py-5'>
              <Row>
                <Col md={{ span: 6, offset: 3 }}>
                  <Alert variant='danger' className='alert-danger-light mb-4'>
                    <Alert.Heading>Authentication wasn't successful!</Alert.Heading>
                    <p>{error.message}</p>
                    <hr />
                    <p>Please, try to log in again!</p>
                  </Alert>
                  <div className='d-grid'>
                    <Button
                      variant='success'
                      onClick={() => ExternalAuthentication.redirect()}
                    >
                      Log in
                    </Button>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        )}
      </Container>
    </>
  )
}

export default ExternalAuthenticationPage
